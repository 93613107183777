<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
           </div>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('table.head.move') }}</span></div>
           <select v-model="reqData.logType">
             <option value="">{{ $t('searchArea.total') }}</option>
             <option value="ADMINLOGIN">{{ $t('searchArea.login') }}</option>
             <option value="read">{{ $t('searchArea.read') }}</option>
             <option value="save">{{ $t('searchArea.write') }}</option>
             <option value="mod">{{ $t('searchArea.modify') }}</option>
           </select>

         </div>
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.adminId') }}</span></div>
           <div>
             <input type="text" v-model="reqData.adminId" />
             <button class="btn-search ml-5" type="button" @click="goSearch">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value" v-if="tableData.length !== 0">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value"  v-if="tableData.length !== 0">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value" v-if="tableData.length !== 0">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="tableData.length!==0">
              <tr v-for="item in tableData" :key="item.boardIdx">
                <td class="roboto">{{item.logIdx}}</td>
                <td class="roboto">{{item.regIp}}</td>
                <td>{{item.memId}}</td>
                <td>
                  <span>{{item.actionText}}</span>
                </td>
                <td>{{item.siteDomain}}</td>
                <td class="col6">{{item.detail}}</td>
                <td class="roboto">{{item.regDate}}</td>
              </tr>
            </template>
            <template v-else>
              <td colspan="7">{{ $t('txt.noData') }}</td>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import lodash from "lodash";
import TableHead from "@/components/main/table/Head.vue";
import DateSelector from "@/components/common/DateSelector";
import Pagination from "@/components/common/Pagination";
import { getSiteData } from "@/libs/auth-helper";
import { settingLogList } from "@/api/setting";
import { replaceDateT, numberWithCommas,getDateStr } from "@/libs/utils.js";
import Memo from '@/components/common/memo'
export default {
  name: "Acclog",
  components: {
    TableHead,
    Pagination,
    DateSelector,
    Memo
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["index", "connectIp", "id", "move", "connectadd", "detailinfo", "transactiontime"]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        adminId: "",
        startDate: "",
        endDate: "",
        logType:""
      },
      siteInfo:{},
      tableData: [],
      pageInfo: {},
      totalInfo: {},
      dateConfigs: {
        enableTime: true,
        dateFormat: "Y-m-d H:i"
      },
      startDefault: "",
      endDefault: ""
    };
  },
  methods: {
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(0, 0, 0);
      } else {
        _date = date[0];
      }
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(23, 59, 59);
      } else {
        _date = date[0];
      }
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    goPage(pageNum){
      this.setTableData(pageNum);
    },
    goSearch(){
      this.setTableData(1);
    },
    async getLogList(page) {
      const req = lodash.cloneDeep(this.reqData);
      const siteId = this.siteInfo.siteId;
      if(page){
        req.page = page
      }
      // if(siteId){
      //   req.siteId = siteId;
      // }
      const res = await settingLogList(req);
      return await res.data;
    },
    async setTableData(page) {
      const data = await this.getLogList(page);
      console.log(data)
      this.tableData = data.list;
      if(data.pageInfo){
        this.pageInfo = data.pageInfo;
      }

      console.log(this.tableData);
      for(const item of this.tableData){
        switch(item.actionType){
          case "ADMINLOGIN":
            item.actionText = "로그인";
            break;
          case "read":
            item.actionText = "읽기";
            break;
          case "mod":
            item.actionText = "수정";
            break;
          case "save":
            item.actionText = "등록";
            break;
        }
        item.regDate = replaceDateT(item.regDate);
      }
      this.pageInfo.tatal_page_count = Math.ceil(Number(this.pageInfo.tatal_list_count) / 30)
      console.log(this.pageInfo);

    }
  },
  async created() {
    this.siteInfo = this.getSiteInfo();
    this.setStartDate();
    this.setEndDate();
    this.setTableData(1);
  }
};
</script>

<style scoped>
  .mainTable tr > td:nth-child(5){
    width:250px;
  }
  .mainTable tr > td:nth-child(6){
    width:800px;
    white-space: normal;
  }
</style>
